import * as React from 'react';
import { IconProps } from '@appTypes';

type Props = {
  secondFill: string;
};

function StarIconHalf(props: IconProps & Props) {
  const { fill, secondFill } = props;
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.10313 3.48313C9.46997 2.73997 10.5297 2.73997 10.8965 3.48313L12.3424 6.41226C12.4879 6.70711 12.7691 6.91157 13.0945 6.95912L16.3294 7.43195C17.1493 7.5518 17.4761 8.55964 16.8825 9.1378L14.5433 11.4162C14.3074 11.6459 14.1998 11.977 14.2554 12.3016L14.8073 15.5191C14.9474 16.3361 14.0898 16.9591 13.3562 16.5732L10.4653 15.0529C10.1739 14.8997 9.82577 14.8997 9.53439 15.0529L6.64347 16.5732C5.90984 16.9591 5.05229 16.3361 5.19241 15.5191L5.74426 12.3016C5.79992 11.977 5.69226 11.6459 5.45638 11.4162L3.11718 9.1378C2.52359 8.55964 2.85037 7.5518 3.67029 7.43195L6.9052 6.95912C7.23056 6.91157 7.51174 6.70711 7.65728 6.41226L9.10313 3.48313Z'
        fill={secondFill}
      />
      <mask id='mask0' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='2' y='2' width='16' height='15'>
        <path
          d='M9.10313 3.48313C9.46997 2.73997 10.5297 2.73997 10.8965 3.48313L12.3424 6.41226C12.4879 6.70711 12.7691 6.91157 13.0945 6.95912L16.3294 7.43195C17.1493 7.5518 17.4761 8.55964 16.8825 9.1378L14.5433 11.4162C14.3074 11.6459 14.1998 11.977 14.2554 12.3016L14.8073 15.5191C14.9474 16.3361 14.0898 16.9591 13.3562 16.5732L10.4653 15.0529C10.1739 14.8997 9.82577 14.8997 9.53439 15.0529L6.64347 16.5732C5.90984 16.9591 5.05229 16.3361 5.19241 15.5191L5.74426 12.3016C5.79992 11.977 5.69226 11.6459 5.45638 11.4162L3.11718 9.1378C2.52359 8.55964 2.85037 7.5518 3.67029 7.43195L6.9052 6.95912C7.23056 6.91157 7.51174 6.70711 7.65728 6.41226L9.10313 3.48313Z'
          fill={secondFill}
        />
      </mask>
      <g mask='url(#mask0)'>
        <rect x='1' width='9' height='18' fill={fill} />
      </g>
    </svg>
  );
}

export default StarIconHalf;
