import * as React from 'react';
import { IconProps } from '@appTypes';

function AppleStoreIcon(props: IconProps) {
  const { fill = '#243636' } = props;
  return (
    <svg width='150' height='50' viewBox='0 0 150 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0)'>
        <path
          d='M137.668 -8.16406e-05H11.9184C11.46 -8.16406e-05 11.0071 -8.16406e-05 10.5499 0.00241836C10.1672 0.00491836 9.78761 0.0121809 9.40125 0.0182934C8.56189 0.0281706 7.72451 0.102014 6.89637 0.239181C6.06941 0.379334 5.26834 0.643563 4.52028 1.02293C3.77312 1.40551 3.09043 1.90263 2.49696 2.49626C1.90038 3.08821 1.40308 3.77244 1.02419 4.52262C0.64425 5.2713 0.380801 6.07359 0.242938 6.90177C0.103764 7.7289 0.028873 8.56557 0.0189375 9.40427C0.0073375 9.78752 0.0061125 10.172 0 10.5553V39.4482C0.0061125 39.8363 0.0073375 40.2123 0.0189375 40.6005C0.028876 41.4392 0.103767 42.2758 0.242938 43.1029C0.38042 43.9316 0.643885 44.7343 1.02419 45.4833C1.40291 46.231 1.90028 46.9125 2.49696 47.5012C3.08818 48.0974 3.77132 48.5949 4.52028 48.9745C5.26833 49.3549 6.06931 49.6207 6.89637 49.763C7.72465 49.8991 8.56195 49.973 9.40125 49.984C9.78761 49.9925 10.1672 49.9974 10.5499 49.9974C11.0071 49.9999 11.46 49.9999 11.9184 49.9999H137.668C138.118 49.9999 138.574 49.9999 139.023 49.9974C139.404 49.9974 139.795 49.9925 140.176 49.984C141.014 49.9736 141.849 49.8997 142.676 49.763C143.506 49.6197 144.309 49.354 145.061 48.9745C145.809 48.5947 146.492 48.0972 147.083 47.5012C147.678 46.9102 148.176 46.2292 148.56 45.4833C148.937 44.7338 149.198 43.9311 149.333 43.1029C149.473 42.2757 149.55 41.4393 149.565 40.6005C149.57 40.2123 149.57 39.8363 149.57 39.4482C149.58 38.994 149.58 38.5424 149.58 38.0809V11.9201C149.58 11.4623 149.58 11.0082 149.57 10.5553C149.57 10.172 149.57 9.78752 149.565 9.40422C149.55 8.56545 149.473 7.72896 149.333 6.90172C149.198 6.07402 148.937 5.2718 148.56 4.52257C147.788 3.01891 146.564 1.79495 145.061 1.02282C144.309 0.644378 143.505 0.380219 142.676 0.239068C141.849 0.101297 141.014 0.0274281 140.176 0.0181184C139.795 0.0120184 139.404 0.00469336 139.023 0.00225586C138.574 -0.000244141 138.118 -0.000244141 137.668 -0.000244141V-8.16406e-05Z'
          fill={fill}
        />
        <path
          d='M10.5561 48.9062C10.1752 48.9062 9.80358 48.9014 9.42572 48.8929C8.64295 48.8827 7.86201 48.8145 7.0893 48.689C6.36878 48.5649 5.6708 48.3341 5.01837 48.0041C4.37192 47.6769 3.78231 47.2478 3.27212 46.7334C2.75455 46.225 2.32364 45.6353 1.99648 44.9878C1.66572 44.336 1.43681 43.6374 1.31773 42.9163C1.18914 42.1414 1.11956 41.3579 1.10961 40.5725C1.10168 40.3089 1.09131 39.4311 1.09131 39.4311V10.5554C1.09131 10.5554 1.10236 9.69116 1.10967 9.43725C1.1192 8.6531 1.18837 7.87082 1.31658 7.09716C1.43588 6.37406 1.66497 5.67344 1.99591 5.01954C2.32186 4.37243 2.75038 3.78233 3.26483 3.2721C3.77871 2.75703 4.37021 2.32576 5.01776 1.99401C5.66869 1.66512 6.36535 1.43592 7.08441 1.31409C7.85966 1.1873 8.64331 1.11875 9.4288 1.10901L10.5567 1.09375H139.017L140.159 1.10963C140.937 1.11887 141.714 1.18681 142.482 1.31286C143.208 1.43622 143.912 1.66702 144.57 1.99768C145.868 2.66623 146.923 3.72395 147.589 5.02259C147.915 5.67197 148.14 6.36688 148.258 7.08374C148.388 7.86374 148.46 8.65217 148.475 9.44275C148.479 9.79675 148.479 10.177 148.479 10.5554C148.489 11.0242 148.489 11.4703 148.489 11.9202V38.081C148.489 38.5351 148.489 38.9783 148.479 39.425C148.479 39.8315 148.479 40.2039 148.474 40.5871C148.46 41.3636 148.388 42.138 148.261 42.904C148.144 43.6303 147.916 44.3344 147.586 44.9915C147.256 45.632 146.828 46.2166 146.316 46.7236C145.805 47.2409 145.215 47.6724 144.567 48.0018C143.91 48.3342 143.207 48.5659 142.482 48.689C141.709 48.8152 140.928 48.8834 140.145 48.8929C139.779 48.9014 139.396 48.9062 139.024 48.9062L137.669 48.9087L10.5561 48.9062Z'
          fill={fill}
        />
        <path
          d='M30.961 25.3757C30.9744 24.3323 31.2516 23.3094 31.7666 22.4019C32.2816 21.4945 33.0178 20.732 33.9067 20.1856C33.342 19.3791 32.5971 18.7155 31.7311 18.2473C30.865 17.7791 29.9017 17.5193 28.9177 17.4885C26.8187 17.2682 24.7838 18.7445 23.7141 18.7445C22.6238 18.7445 20.9769 17.5104 19.2035 17.5468C18.0564 17.5839 16.9385 17.9175 15.9586 18.515C14.9788 19.1126 14.1704 19.9538 13.6123 20.9567C11.1948 25.1422 12.9981 31.2935 15.3138 34.6768C16.4725 36.3335 17.8266 38.184 19.5984 38.1184C21.3322 38.0465 21.9798 37.0128 24.0726 37.0128C26.1461 37.0128 26.7536 38.1184 28.5614 38.0767C30.4219 38.0465 31.594 36.4126 32.712 34.7403C33.5445 33.5598 34.1851 32.2552 34.61 30.8746C33.5292 30.4175 32.6068 29.6522 31.9579 28.6744C31.309 27.6965 30.9623 26.5492 30.961 25.3757Z'
          fill='white'
        />
        <path
          d='M27.5465 15.2634C28.5609 14.0457 29.0606 12.4804 28.9396 10.9001C27.3898 11.0629 25.9583 11.8036 24.9302 12.9747C24.4275 13.5467 24.0425 14.2123 23.7972 14.9332C23.5519 15.6542 23.4511 16.4165 23.5005 17.1764C24.2757 17.1844 25.0426 17.0164 25.7434 16.685C26.4442 16.3536 27.0607 15.8676 27.5465 15.2634Z'
          fill='white'
        />
        <path
          d='M52.8779 33.9243H46.9612L45.5403 38.1199H43.0342L48.6384 22.5974H51.2422L56.8464 38.1199H54.2976L52.8779 33.9243ZM47.574 31.9883H52.264L49.952 25.1792H49.8873L47.574 31.9883Z'
          fill='white'
        />
        <path
          d='M68.9495 32.4621C68.9495 35.9789 67.0672 38.2384 64.2266 38.2384C63.507 38.2761 62.7914 38.1103 62.1617 37.7601C61.5319 37.41 61.0135 36.8895 60.6658 36.2584H60.6121V41.8639H58.2891V26.8029H60.5376V28.6852H60.5804C60.9441 28.0571 61.4712 27.5394 62.1058 27.1871C62.7403 26.8348 63.4585 26.6611 64.1839 26.6845C67.0561 26.6845 68.9495 28.955 68.9495 32.4621ZM66.5618 32.4621C66.5618 30.1708 65.3777 28.6644 63.5711 28.6644C61.7962 28.6644 60.6023 30.2025 60.6023 32.4621C60.6023 34.7423 61.7962 36.2694 63.5711 36.2694C65.3777 36.2694 66.5618 34.7741 66.5618 32.4621Z'
          fill='white'
        />
        <path
          d='M81.4056 32.462C81.4056 35.9789 79.5232 38.2384 76.6827 38.2384C75.9631 38.276 75.2475 38.1103 74.6177 37.7601C73.988 37.4099 73.4696 36.8895 73.1219 36.2584H73.0681V41.8639H70.7451V26.8029H72.9936V28.6852H73.0364C73.4001 28.0571 73.9272 27.5394 74.5618 27.1871C75.1963 26.8348 75.9145 26.6611 76.6399 26.6845C79.5122 26.6845 81.4056 28.955 81.4056 32.462ZM79.0179 32.462C79.0179 30.1708 77.8338 28.6644 76.0271 28.6644C74.2522 28.6644 73.0584 30.2025 73.0584 32.462C73.0584 34.7423 74.2522 36.2694 76.0271 36.2694C77.8338 36.2694 79.0179 34.7741 79.0179 32.462H79.0179Z'
          fill='white'
        />
        <path
          d='M89.6382 33.795C89.8103 35.3343 91.3057 36.345 93.3492 36.345C95.3072 36.345 96.7159 35.3342 96.7159 33.9463C96.7159 32.7415 95.8662 32.02 93.8545 31.5256L91.8428 31.041C88.9925 30.3525 87.6692 29.0195 87.6692 26.8564C87.6692 24.1782 90.0032 22.3386 93.3174 22.3386C96.5974 22.3386 98.846 24.1782 98.9217 26.8564H96.5767C96.4363 25.3074 95.1558 24.3723 93.2844 24.3723C91.4131 24.3723 90.1326 25.3184 90.1326 26.6953C90.1326 27.7927 90.9505 28.4385 92.9512 28.9328L94.6614 29.3527C97.8462 30.1059 99.1694 31.3852 99.1694 33.6557C99.1694 36.5598 96.8562 38.3786 93.177 38.3786C89.7346 38.3786 87.4104 36.6025 87.2603 33.7949L89.6382 33.795Z'
          fill='white'
        />
        <path
          d='M104.183 24.1245V26.8027H106.335V28.6423H104.183V34.8813C104.183 35.8506 104.614 36.3022 105.56 36.3022C105.815 36.2978 106.07 36.2799 106.324 36.2485V38.0771C105.898 38.1566 105.466 38.1926 105.033 38.1845C102.742 38.1845 101.849 37.3239 101.849 35.1291V28.6423H100.203V26.8027H101.849V24.1245H104.183Z'
          fill='white'
        />
        <path
          d='M107.582 32.4619C107.582 28.9011 109.679 26.6636 112.949 26.6636C116.23 26.6636 118.318 28.9011 118.318 32.4619C118.318 36.0325 116.241 38.2602 112.949 38.2602C109.658 38.2602 107.582 36.0325 107.582 32.4619ZM115.951 32.4619C115.951 30.0193 114.831 28.5776 112.949 28.5776C111.067 28.5776 109.948 30.0303 109.948 32.4619C109.948 34.9143 111.067 36.345 112.949 36.345C114.831 36.345 115.951 34.9143 115.951 32.4619H115.951Z'
          fill='white'
        />
        <path
          d='M120.232 26.8028H122.448V28.729H122.502C122.652 28.1274 123.004 27.5957 123.5 27.2234C123.996 26.851 124.604 26.6607 125.224 26.6843C125.492 26.6834 125.759 26.7125 126.02 26.771V28.9439C125.682 28.8406 125.329 28.7932 124.976 28.8035C124.639 28.7898 124.302 28.8493 123.99 28.9779C123.678 29.1065 123.397 29.3012 123.167 29.5485C122.937 29.7959 122.763 30.0901 122.658 30.4109C122.552 30.7318 122.517 31.0717 122.555 31.4072V38.1199H120.232L120.232 26.8028Z'
          fill='white'
        />
        <path
          d='M136.73 34.7959C136.418 36.8503 134.417 38.2603 131.857 38.2603C128.565 38.2603 126.521 36.0544 126.521 32.5156C126.521 28.9658 128.576 26.6636 131.76 26.6636C134.891 26.6636 136.86 28.8144 136.86 32.2458V33.0417H128.866V33.1821C128.83 33.5986 128.882 34.0182 129.019 34.413C129.157 34.8078 129.377 35.1688 129.665 35.472C129.953 35.7752 130.302 36.0136 130.69 36.1715C131.077 36.3294 131.493 36.4031 131.911 36.3877C132.46 36.4391 133.011 36.312 133.482 36.0252C133.953 35.7384 134.318 35.3072 134.524 34.7959L136.73 34.7959ZM128.877 31.4182H134.535C134.556 31.0437 134.499 30.669 134.369 30.3174C134.238 29.9659 134.036 29.6453 133.775 29.3756C133.514 29.1058 133.201 28.8929 132.854 28.7502C132.507 28.6074 132.135 28.5378 131.76 28.5459C131.381 28.5436 131.006 28.6163 130.656 28.7598C130.306 28.9032 129.988 29.1145 129.72 29.3816C129.452 29.6486 129.24 29.9661 129.095 30.3156C128.95 30.6652 128.876 31.0399 128.877 31.4182V31.4182Z'
          fill='white'
        />
        <path
          d='M47.283 10.9135C47.77 10.8785 48.2587 10.9521 48.7138 11.1289C49.169 11.3057 49.5792 11.5813 49.9149 11.9358C50.2506 12.2904 50.5035 12.715 50.6553 13.1791C50.807 13.6432 50.8539 14.1351 50.7925 14.6195C50.7925 17.0024 49.5046 18.372 47.283 18.372H44.5889V10.9135H47.283ZM45.7473 17.3172H47.1536C47.5016 17.338 47.8498 17.281 48.173 17.1502C48.4962 17.0195 48.7861 16.8183 49.0217 16.5613C49.2574 16.3044 49.4327 15.9981 49.535 15.6648C49.6374 15.3315 49.6641 14.9796 49.6133 14.6347C49.6604 14.2912 49.631 13.9415 49.5271 13.6107C49.4232 13.2798 49.2474 12.9761 49.0123 12.7212C48.7772 12.4662 48.4887 12.2665 48.1673 12.1362C47.846 12.0059 47.4998 11.9483 47.1536 11.9675H45.7473V17.3172Z'
          fill='white'
        />
        <path
          d='M52.1009 15.5552C52.0655 15.1853 52.1079 14.8121 52.2252 14.4595C52.3425 14.107 52.5322 13.7828 52.7821 13.5078C53.032 13.2328 53.3367 13.0131 53.6765 12.8628C54.0163 12.7124 54.3838 12.6348 54.7554 12.6348C55.1269 12.6348 55.4944 12.7124 55.8342 12.8628C56.1741 13.0131 56.4787 13.2328 56.7286 13.5078C56.9785 13.7828 57.1682 14.107 57.2855 14.4595C57.4028 14.8121 57.4452 15.1853 57.4098 15.5552C57.4458 15.9255 57.404 16.2992 57.287 16.6524C57.17 17.0056 56.9805 17.3303 56.7305 17.6058C56.4805 17.8814 56.1756 18.1015 55.8355 18.2522C55.4953 18.4029 55.1274 18.4807 54.7554 18.4807C54.3833 18.4807 54.0154 18.4029 53.6753 18.2522C53.3351 18.1015 53.0302 17.8814 52.7802 17.6058C52.5303 17.3303 52.3407 17.0056 52.2237 16.6524C52.1067 16.2992 52.0649 15.9255 52.1009 15.5552ZM56.2672 15.5552C56.2672 14.3352 55.7191 13.6217 54.7572 13.6217C53.7916 13.6217 53.2484 14.3352 53.2484 15.5553C53.2484 16.7851 53.7916 17.4931 54.7572 17.4931C55.7191 17.4931 56.2672 16.7802 56.2672 15.5552H56.2672Z'
          fill='white'
        />
        <path
          d='M64.4668 18.3721H63.3145L62.1511 14.2266H62.0632L60.9048 18.3721H59.7634L58.2119 12.7434H59.3386L60.3469 17.0384H60.4299L61.5872 12.7434H62.6528L63.8101 17.0384H63.898L64.9014 12.7434H66.0122L64.4668 18.3721Z'
          fill='white'
        />
        <path
          d='M67.3169 12.7433H68.3862V13.6375H68.4692C68.6101 13.3164 68.8476 13.0471 69.1487 12.8674C69.4497 12.6876 69.7994 12.6063 70.1489 12.6347C70.4228 12.6141 70.6978 12.6554 70.9536 12.7555C71.2093 12.8556 71.4392 13.012 71.6263 13.2131C71.8135 13.4141 71.9529 13.6547 72.0344 13.917C72.1159 14.1793 72.1374 14.4565 72.0972 14.7282V18.3719H70.9863V15.0071C70.9863 14.1026 70.5933 13.6528 69.7717 13.6528C69.5858 13.6441 69.4002 13.6758 69.2276 13.7455C69.055 13.8153 68.8996 13.9216 68.7719 14.0571C68.6443 14.1926 68.5474 14.3541 68.488 14.5305C68.4286 14.7069 68.408 14.8941 68.4277 15.0792V18.372H67.3169L67.3169 12.7433Z'
          fill='white'
        />
        <path d='M73.8672 10.5461H74.978V18.3721H73.8672V10.5461Z' fill='white' />
        <path
          d='M76.5223 15.5553C76.4869 15.1854 76.5293 14.8122 76.6466 14.4596C76.7639 14.107 76.9537 13.7828 77.2036 13.5078C77.4536 13.2328 77.7582 13.0131 78.0981 12.8628C78.4379 12.7124 78.8054 12.6348 79.177 12.6348C79.5486 12.6348 79.9161 12.7124 80.256 12.8628C80.5958 13.0131 80.9005 13.2328 81.1504 13.5078C81.4004 13.7828 81.5901 14.107 81.7074 14.4596C81.8248 14.8122 81.8671 15.1854 81.8317 15.5553C81.8677 15.9256 81.8259 16.2994 81.7089 16.6525C81.5918 17.0057 81.4022 17.3304 81.1522 17.606C80.9022 17.8815 80.5973 18.1016 80.2571 18.2523C79.917 18.4029 79.5491 18.4808 79.177 18.4808C78.805 18.4808 78.4371 18.4029 78.0969 18.2523C77.7567 18.1016 77.4518 17.8815 77.2018 17.606C76.9518 17.3304 76.7622 17.0057 76.6452 16.6525C76.5281 16.2994 76.4863 15.9256 76.5223 15.5553ZM80.6885 15.5553C80.6885 14.3353 80.1404 13.6218 79.1785 13.6218C78.213 13.6218 77.6698 14.3353 77.6698 15.5554C77.6698 16.7852 78.213 17.4932 79.1785 17.4932C80.1405 17.4932 80.6885 16.7803 80.6885 15.5553H80.6885Z'
          fill='white'
        />
        <path
          d='M83.0015 16.7803C83.0015 15.7671 83.7559 15.183 85.095 15.1L86.6196 15.0121V14.5262C86.6196 13.9318 86.2266 13.5961 85.4673 13.5961C84.8472 13.5961 84.4175 13.8237 84.2942 14.2217H83.2188C83.3323 13.2549 84.2417 12.6348 85.5186 12.6348C86.9297 12.6348 87.7256 13.3373 87.7256 14.5262V18.3721H86.6562V17.5811H86.5684C86.39 17.8648 86.1395 18.0961 85.8424 18.2514C85.5454 18.4066 85.2125 18.4803 84.8777 18.4648C84.6414 18.4894 84.4026 18.4642 84.1766 18.3908C83.9507 18.3174 83.7426 18.1975 83.5658 18.0388C83.3891 17.8801 83.2475 17.6861 83.1503 17.4693C83.0531 17.2526 83.0024 17.0178 83.0015 16.7803ZM86.6196 16.2993V15.8287L85.2451 15.9166C84.47 15.9685 84.1184 16.2322 84.1184 16.7284C84.1184 17.235 84.5578 17.5298 85.1621 17.5298C85.3392 17.5477 85.5181 17.5298 85.6881 17.4772C85.8581 17.4246 86.0158 17.3383 86.1517 17.2235C86.2877 17.1087 86.3992 16.9677 86.4796 16.809C86.56 16.6502 86.6076 16.4769 86.6196 16.2993Z'
          fill='white'
        />
        <path
          d='M89.1855 15.5553C89.1855 13.7768 90.0999 12.65 91.522 12.65C91.8737 12.6338 92.2229 12.7181 92.5285 12.893C92.8342 13.0678 93.0837 13.3261 93.248 13.6375H93.3311V10.5461H94.4419V18.3721H93.3775V17.4828H93.2896C93.1126 17.7921 92.8543 18.0472 92.5427 18.2203C92.2312 18.3934 91.8782 18.478 91.522 18.4648C90.0901 18.4649 89.1855 17.3382 89.1855 15.5553ZM90.333 15.5553C90.333 16.7492 90.8958 17.4676 91.837 17.4676C92.7732 17.4676 93.3518 16.7388 93.3518 15.5602C93.3518 14.3871 92.7671 13.648 91.837 13.648C90.9019 13.648 90.333 14.3712 90.333 15.5553H90.333Z'
          fill='white'
        />
        <path
          d='M99.0375 15.5552C99.0021 15.1853 99.0444 14.8121 99.1617 14.4595C99.279 14.107 99.4687 13.7828 99.7186 13.5078C99.9685 13.2328 100.273 13.0131 100.613 12.8628C100.953 12.7124 101.32 12.6348 101.692 12.6348C102.063 12.6348 102.431 12.7124 102.771 12.8628C103.111 13.0131 103.415 13.2328 103.665 13.5078C103.915 13.7828 104.105 14.107 104.222 14.4595C104.339 14.8121 104.382 15.1853 104.346 15.5552C104.382 15.9255 104.341 16.2992 104.224 16.6524C104.107 17.0056 103.917 17.3303 103.667 17.6058C103.417 17.8814 103.112 18.1015 102.772 18.2522C102.432 18.4029 102.064 18.4807 101.692 18.4807C101.32 18.4807 100.952 18.4029 100.612 18.2522C100.272 18.1015 99.9668 17.8814 99.7168 17.6058C99.4668 17.3303 99.2772 17.0056 99.1602 16.6524C99.0432 16.2992 99.0014 15.9255 99.0375 15.5552ZM103.204 15.5552C103.204 14.3352 102.656 13.6217 101.694 13.6217C100.728 13.6217 100.185 14.3352 100.185 15.5553C100.185 16.7851 100.728 17.4931 101.694 17.4931C102.656 17.4931 103.204 16.7802 103.204 15.5552Z'
          fill='white'
        />
        <path
          d='M105.837 12.7433H106.906V13.6375H106.989C107.13 13.3164 107.368 13.0471 107.669 12.8674C107.97 12.6876 108.319 12.6063 108.669 12.6347C108.943 12.6141 109.218 12.6554 109.474 12.7555C109.729 12.8556 109.959 13.012 110.146 13.2131C110.333 13.4141 110.473 13.6547 110.554 13.917C110.636 14.1793 110.657 14.4565 110.617 14.7282V18.3719H109.506V15.0071C109.506 14.1026 109.113 13.6528 108.292 13.6528C108.106 13.6441 107.92 13.6758 107.748 13.7455C107.575 13.8153 107.42 13.9216 107.292 14.0571C107.164 14.1926 107.067 14.3541 107.008 14.5305C106.949 14.7069 106.928 14.8941 106.948 15.0792V18.372H105.837V12.7433Z'
          fill='white'
        />
        <path
          d='M116.894 11.342V12.769H118.114V13.7047H116.894V16.599C116.894 17.1886 117.137 17.4468 117.69 17.4468C117.832 17.4463 117.973 17.4378 118.114 17.4211V18.3464C117.914 18.3821 117.712 18.4011 117.51 18.4032C116.274 18.4032 115.782 17.9686 115.782 16.8834V13.7047H114.889V12.769H115.782V11.342H116.894Z'
          fill='white'
        />
        <path
          d='M119.631 10.5461H120.732V13.648H120.82C120.967 13.3238 121.212 13.0531 121.519 12.8726C121.826 12.6922 122.181 12.6108 122.536 12.6397C122.809 12.6248 123.081 12.6703 123.334 12.7729C123.587 12.8754 123.814 13.0325 123.999 13.2329C124.184 13.4334 124.323 13.6722 124.405 13.9323C124.487 14.1925 124.511 14.4676 124.475 14.738V18.3721H123.363V15.0121C123.363 14.113 122.944 13.6577 122.159 13.6577C121.968 13.642 121.776 13.6683 121.596 13.7346C121.417 13.8009 121.254 13.9057 121.119 14.0416C120.984 14.1776 120.88 14.3413 120.815 14.5215C120.75 14.7017 120.725 14.8939 120.742 15.0847V18.3721H119.631L119.631 10.5461Z'
          fill='white'
        />
        <path
          d='M130.952 16.8524C130.801 17.3668 130.474 17.8118 130.028 18.1095C129.582 18.4072 129.046 18.5386 128.513 18.4808C128.142 18.4906 127.773 18.4196 127.433 18.2727C127.092 18.1257 126.788 17.9065 126.54 17.63C126.293 17.3536 126.109 17.0267 126 16.6719C125.892 16.3171 125.862 15.943 125.913 15.5755C125.863 15.2069 125.894 14.832 126.002 14.4762C126.11 14.1203 126.293 13.7919 126.539 13.513C126.785 13.2342 127.088 13.0115 127.428 12.86C127.768 12.7085 128.136 12.6317 128.508 12.6349C130.074 12.6349 131.019 13.7049 131.019 15.4724V15.86H127.044V15.9222C127.027 16.1288 127.053 16.3367 127.12 16.5326C127.188 16.7286 127.296 16.9082 127.437 17.06C127.578 17.2118 127.75 17.3325 127.94 17.4142C128.131 17.496 128.336 17.537 128.543 17.5347C128.809 17.5666 129.078 17.5188 129.317 17.3973C129.555 17.2758 129.752 17.0861 129.882 16.8523L130.952 16.8524ZM127.044 15.0384H129.887C129.901 14.8495 129.876 14.6598 129.812 14.4814C129.748 14.303 129.648 14.1399 129.517 14.0025C129.387 13.8652 129.229 13.7567 129.054 13.684C128.879 13.6113 128.691 13.576 128.502 13.5803C128.31 13.5779 128.119 13.614 127.941 13.6864C127.763 13.7588 127.601 13.8661 127.466 14.002C127.33 14.1379 127.222 14.2996 127.15 14.4776C127.078 14.6556 127.042 14.8463 127.044 15.0384H127.044Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='149.58' height='50' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AppleStoreIcon;
