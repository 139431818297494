import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import StepsTimeline from '@components/StepsTimeline';
import { theme } from '@styles';
import MobileFrame from '@assets/images/frame.svg';

const FRAME_HEIGHT_MOBILE = 365;
const FRAME_WIDTH_MOBILE = 177;

const VIDEO_HEIGHT_MOBILE = 350;
const VIDEO_WIDTH_MOBILE = 162;

const FRAME_HEIGHT_DESKTOP = 620;
const FRAME_WIDTH_DESKTOP = 312;

const VIDEO_HEIGHT_DESKTOP = 600;
const VIDEO_WIDTH_DESKTOP = 275;

const { breakpoints } = theme;

type Step = {
  step_title?: string;
  step_subtitle: string;
  step_label?: string;
  intermediate_step?: boolean;
};

type Props = {
  steps: Step[];
  placeholdersMapper: string[];
  appName?: string;
};

const StepsCarousel: React.FC<Props> = ({ steps, placeholdersMapper, appName }) => {
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const carouselLoopRef = useRef<ReturnType<typeof setTimeout>>(setTimeout(() => '', 0));

  useEffect(() => {
    carouselLoopRef.current = setTimeout(() => {
      if (currentStep < placeholdersMapper.length - 1) {
        setCurrentStep(currentStep + 1);
        setCurrentVideoId(currentStep + 1);
      } else {
        setCurrentStep(0);
        setCurrentVideoId(0);
      }
    }, 3000);
  }, [currentStep]);

  const handleOnStepChange = (step: number) => {
    setCurrentStep(step);
    setCurrentVideoId(step);
    clearTimeout(carouselLoopRef.current);
  };

  return (
    <Container>
      <StepPhone>
        <PhoneFrame alt='Phone frame' src={MobileFrame} width={301} height={620}></PhoneFrame>
        {placeholdersMapper.map(
          (_, index) =>
            steps[index] && (
              <PlaceholderImage
                alt={steps[index].step_title ? steps[index].step_title : steps[index].step_subtitle}
                key={index}
                showImage={currentVideoId === index}
                src={placeholdersMapper[index]}
              />
            ),
        )}
        <PhoneName>{appName}</PhoneName>
      </StepPhone>
      <StepsTimeline
        currentStep={currentStep}
        onStepChange={(step: number) => handleOnStepChange(step)}
        stepsArray={steps}
      />
    </Container>
  );
};

export default StepsCarousel;

const PhoneName = styled.div`
  display: none;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
    margin-top: 42px;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.1px;
    font-weight: 700;
    font-family: 'Poppins';
  }
`;

const StepPhone = styled.div`
  position: relative;
  margin-bottom: 40px;
  align-self: center;
  user-select: none;
  width: ${FRAME_WIDTH_MOBILE}px;
  height: ${FRAME_HEIGHT_MOBILE}px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-right: 40px;
    margin-left: 0;
    margin-bottom: 0;
    width: ${FRAME_WIDTH_DESKTOP}px;
    height: ${FRAME_HEIGHT_DESKTOP}px;
  }
`;

const PlaceholderImage = styled.img<{ showImage: boolean }>`
  transition: opacity 250ms ease-in;
  opacity: ${(props) => (props.showImage ? 1 : 0)};
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${VIDEO_WIDTH_MOBILE}px;
  height: ${VIDEO_HEIGHT_MOBILE}px;
  border-radius: 14px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    width: ${VIDEO_WIDTH_DESKTOP}px;
    height: ${VIDEO_HEIGHT_DESKTOP}px;
  }
`;

const PhoneFrame = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: ${FRAME_WIDTH_MOBILE}px;
  height: ${FRAME_HEIGHT_MOBILE}px;

  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    width: ${FRAME_WIDTH_DESKTOP}px;
    height: ${FRAME_HEIGHT_DESKTOP}px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
