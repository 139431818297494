import * as React from 'react';
import { IconProps } from '@appTypes';

function StarIcon(props: IconProps) {
  const { fill } = props;
  return (
    <svg width={16} height={15} viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7.103 1.483a1 1 0 011.794 0l1.445 2.929a1 1 0 00.752.547l3.235.472a1 1 0 01.553 1.706l-2.339 2.279a1 1 0 00-.288.885l.552 3.218a1 1 0 01-1.45 1.054l-2.892-1.52a1 1 0 00-.93 0l-2.892 1.52a1 1 0 01-1.45-1.054l.551-3.218a1 1 0 00-.288-.885L1.117 7.137a1 1 0 01.553-1.706l3.235-.472a1 1 0 00.752-.547l1.446-2.93z'
        fill={fill}
      />
    </svg>
  );
}

export default StarIcon;
