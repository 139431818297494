import React from 'react';
import styled from '@emotion/styled';
import { theme } from '@styles';
import RatingStars from './RatingStars';
import { CANDLELIGHT, GRANNY_SMITH, OUTER_SPACE } from '@constants';

const { colors, breakpoints } = theme;

type Props = {
  icon: string;
  name: string;
  rating: number;
  ratingStars?: string;
  borderedIcon?: boolean;
  iconBgColor?: string;
};

const MobileApp: React.FC<Props> = ({ icon, name, rating, borderedIcon, iconBgColor }) => {
  return (
    <ComponentWrapper>
      <IconWrapper borderedIcon={borderedIcon} iconBgColor={iconBgColor}>
        <img width={42} height={40.6} src={icon} alt='App logo' />
      </IconWrapper>
      <Body>
        <Name>{name}</Name>
        <RatingWrapper>
          <RatingText>{rating}</RatingText>
          <RatingStars rating={rating} fill={CANDLELIGHT} />
        </RatingWrapper>
      </Body>
    </ComponentWrapper>
  );
};

export default MobileApp;

const RatingText = styled.div`
  font-size: 14px;
  line-height: 18.2px;
  font-weight: 400;
  color: ${OUTER_SPACE};
  margin-right: 6px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 16px;
    line-height: 20.8px;
    color: ${GRANNY_SMITH};
    margin-right: 10px;
  }
`;

const Name = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  margin-right: 8px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.1px;
    margin-right: 0px;
  }
`;

const Body = styled.div`
  display: flex;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    flex-direction: column;
  }
`;

const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    height: 60px;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{ borderedIcon?: boolean; iconBgColor?: string }>`
  margin-right: 8px;
  border: ${({ borderedIcon }) => (borderedIcon ? `1px solid ${colors.mystic}` : `1px solid transparent`)};
  border-radius: 4.8px;
  display: inline-flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  img {
    width: 16px;
  }
  background-color: ${({ iconBgColor }) => (iconBgColor ? iconBgColor : 'transparent')};
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-right: 16px;
    border-radius: 12px;
    border: ${({ borderedIcon }) => (borderedIcon ? `2px solid ${colors.mystic}` : `2px solid transparent`)};
    width: 60px;
    height: 60px;
    img {
      width: initial;
    }
  }
`;
