import React from 'react';
import styled from '@emotion/styled';
import StarIcon from '@assets/icons/StarIcon';
import { MYSTIC } from '@constants';
import StarIconHalf from '@assets/icons/StarIconHalf';

type Props = {
  rating: number;
  fill: string;
};

const RatingStars: React.FC<Props> = ({ rating, fill }) => {
  return (
    <Wrapper>
      {[...Array(5)].map((_, i) => {
        if (rating - i > 0 && rating - i < 0.5) {
          return <StarIconHalf fill={fill} secondFill={MYSTIC} />;
        } else if (i < rating) {
          return <StarIcon key={i} fill={fill} />;
        } else {
          return <StarIcon key={i} fill={MYSTIC} />;
        }
      })}
    </Wrapper>
  );
};

export default RatingStars;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
