import React from 'react';
import StepsCarousel from '@components/StepsCarousel';
import { SectionSubtitle, SectionTitle, SectionWrapper } from '@styles';
import { TraineesHowItWorksSectionData } from './types';

const HowItWorksSection: React.FC<TraineesHowItWorksSectionData> = (props) => {
  const { how_it_works_title, how_it_works_subtitle, how_it_works_steps } = props;
  const placeholdersMapper = how_it_works_steps.map((step) => step.step_image.url);
  return (
    <SectionWrapper>
      <SectionTitle>{how_it_works_title.text}</SectionTitle>
      <SectionSubtitle>{how_it_works_subtitle.text}</SectionSubtitle>
      <StepsCarousel steps={how_it_works_steps} placeholdersMapper={placeholdersMapper} />
    </SectionWrapper>
  );
};

export default HowItWorksSection;
