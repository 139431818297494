import React from 'react';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { PageContext, IPrismicClub } from '@appTypes';
import Separator from '@components/Separator';
import HeaderSection from '@pageSections/traineesPage/header';
import HowItWorksSection from '@pageSections/traineesPage/how-it-works';
import BenefitsSection from '@pageSections/traineesPage/benefits';
import FAQSection from '@pageSections/traineesPage/faq';
import FooterSection from '@pageSections/common/footer';

import { Helmet } from 'react-helmet';
import CallToActionSection from '@pageSections/common/callToAction';

const ClubPage: React.FC<PageProps<IPrismicClub, PageContext>> = ({
  data: { prismicData, prismicFooterData, prismicCtaData },
  pageContext,
  location,
}) => {
  const { locale } = pageContext;
  const data = prismicData!.edges[0].node.data;

  const { meta_title, meta_description, meta_image, meta_url, faq_items } = data;

  const faqSchemaOrgJSONLD = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': faq_items.map((item) => {
      return {
        '@type': 'Question',
        'name': item.faq_question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': item.faq_answer.html,
        },
      };
    }),
  };

  return (
    <>
      <SEO
        title={meta_title}
        description={meta_description}
        image={meta_image.url}
        url={meta_url}
        pathname={location.pathname}
        name='Hyperhuman'
        locale={locale}
      />
      <Helmet>
        <script type='application/ld+json'>{JSON.stringify(faqSchemaOrgJSONLD)}</script>
      </Helmet>
      <Container>
        <HeaderSection {...data} />
        <Separator />
        <HowItWorksSection {...data} />
        <Separator />
        <BenefitsSection {...data} />
        <Separator />
        <FAQSection {...data} />
        <CallToActionSection prismicCtaData={prismicCtaData} />
        <FooterSection prismicFooterData={prismicFooterData} subscribeForm />
      </Container>
    </>
  );
};

export default ClubPage;

const Container = styled.div`
  position: initial;
`;

export const pageQuery = graphql`
  query ClubQuery($locale: String!) {
    prismicData: allPrismicClub(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            meta_title
            meta_image {
              url
            }
            meta_description
            meta_keywords
            meta_url
            header_title {
              text
            }
            header_subtitle {
              text
            }
            app_icon {
              url
            }
            app_name
            app_rating
            app_rating_stars {
              url
            }
            app_screenshot {
              url
            }
            ios_app_link
            ios_app_id
            android_app_id
            android_app_link
            get_started_title
            get_started_subtitle
            first_name_input_label
            first_name_input_placeholder
            last_name_input_label
            last_name_input_placeholder
            your_email_input_label
            your_email_input_placeholder
            trainer_email_input_label
            trainer_email_input_placeholder
            how_it_works_title {
              text
            }
            how_it_works_subtitle {
              text
            }
            how_it_works_steps {
              step_image {
                url
              }
              video_id
              step_label
              step_title
              step_subtitle
              intermediate_step
            }
            mobile_frame {
              url
            }
            benefits_title {
              text
            }
            benefits_subtitle {
              text
            }
            benefits {
              benefit_icon {
                url
              }
              benefit_title
              benefit_subtitle
            }
            faq_title
            faq_subtitle
            faq_items {
              faq_question
              faq_answer {
                html
              }
            }
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
  }
`;
