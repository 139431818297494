import React from 'react';
import styled from '@emotion/styled';
import { HeaderTitle, HeaderSubtitle, theme, SectionWrapper as SectionWrapperStyles } from '@styles';
import MobileApp from '@components/MobileApp';
import AppStore from '@components/AppleStore';
import GooglePlay from '@components/GoogleStore';
import { TraineesHeaderSectionData } from './types';

const { breakpoints } = theme;

const HeaderSection: React.FC<TraineesHeaderSectionData> = (props) => {
  const {
    header_title,
    header_subtitle,
    app_icon,
    app_name,
    app_rating,
    app_rating_stars,
    app_screenshot,
    ios_app_id,
    ios_app_link,
    android_app_id,
    android_app_link,
  } = props;

  return (
    <SectionWrapper>
      <HeaderTitle>{header_title.text}</HeaderTitle>
      <HeaderSubtitle>{header_subtitle.text}</HeaderSubtitle>
      <AppInfoWrapper>
        <MobileApp
          icon={app_icon && app_icon.url}
          name={app_name}
          rating={app_rating}
          ratingStars={app_rating_stars && app_rating_stars.url}
          borderedIcon
        />
        <StoreButtons>
          <AppStoreStyles>
            <AppStore url={ios_app_link} id={ios_app_id} />
          </AppStoreStyles>
          <GooglePlayStoreStyles>
            <GooglePlay url={android_app_link} id={android_app_id} />
          </GooglePlayStoreStyles>
        </StoreButtons>
      </AppInfoWrapper>
      <ScreenshotWrapper>
        <img src={app_screenshot && app_screenshot.url} alt='App screenshot' />
      </ScreenshotWrapper>
    </SectionWrapper>
  );
};

export default HeaderSection;

const AppStoreStyles = styled.div`
  margin-right: 25px;
  a {
    display: inline-flex;
  }
  svg {
    height: 40px;
    width: 122px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-right: 40px;
    svg {
      width: 131px;
      height: 44px;
    }
  }
`;

const GooglePlayStoreStyles = styled.div`
  svg {
    height: 40px;
    width: 138px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    svg {
      width: 148.5px;
      height: 44px;
    }
  }
`;

const SectionWrapper = styled(SectionWrapperStyles)`
  padding: 0 24px;
  margin-bottom: 132%;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-bottom: 100px;
  }
`;

const ScreenshotWrapper = styled.div`
  margin: 0 auto;
  position: absolute;
  left: -15%;
  overflow: hidden;
  img {
    position: relative;
    left: 7.5%;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    left: 0px;
    position: relative;
    max-width: 730px;
    img {
      position: initial;
      left: initial;
    }
  }
`;

const StoreButtons = styled.div`
  display: flex;
  margin-top: 16px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 0px;
  }
`;

const AppInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 48px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 548px;
    margin: 48px auto 72px auto;
  }
`;
