import React from 'react';
import styled from '@emotion/styled';
import GoogleStoreIcon from '@assets/icons/GoogleStoreIcon';

type Props = {
  url: string;
  id: string;
  backgroundColor?: string;
  width?: number;
  height?: number;
};

const GoogleStore: React.FC<Props> = ({ url, id, backgroundColor, width = 168, height = 50 }) => {
  return (
    <a href={url} target='_blank' rel='noreferrer' id={id}>
      <Button id={id}>
        <GoogleStoreIcon width={width} height={height} fill={backgroundColor} />
      </Button>
    </a>
  );
};

export default GoogleStore;

const Button = styled.div`
  display: inline-block;
  transform: translateY(0px);
  &:hover {
    cursor: pointer;
    transform: translateY(-3px);
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  }
  transition: all 250ms ease-in-out;
`;
